import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import closeIcon from 'src/assets/modal/close.svg';
import campaign from 'src/assets/main/campaign.svg';
import active from 'src/assets/main/notifications_active.svg';
import mission from 'src/assets/main/task_alt.svg';

interface NotificationDetailModalProps {
  notification: {
    title: string;
    message: string;
    created_at: string;
    notification_type: string;
  };
  onClose: () => void;
}

const NotificationDetailModal: React.FC<NotificationDetailModalProps> = ({ notification, onClose }) => {
  console.log('모달창', notification);

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'announcement':
        return campaign;
      case 'active':
        return active;
      case 'mission':
        return mission;
      default:
        return campaign; // 기본값
    }
  };

  const getNotificationText = (type: string) => {
    switch (type) {
      case 'announcement':
        return 'Notification';
      case 'active':
        return 'Active';
      case 'mission':
        return 'Mission';
      default:
        return 'Notification'; // 기본값
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit', // 수정된 부분
      minute: '2-digit', // 수정된 부분
      hour12: true, // A.M/P.M 형식 활성화
    };

    if (isToday) return `Today ${date.toLocaleTimeString('en-US', timeOptions)}`;
    if (isYesterday) return `Yesterday ${date.toLocaleTimeString('en-US', timeOptions)}`;

    // Default to full date if not today or yesterday
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const portalRoot = document.getElementById('portal-root') || document.body;

  return ReactDOM.createPortal(
    <Overlay onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        {/* <CloseButton onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </CloseButton> */}
        <Type>
          <img src={getNotificationIcon(notification.notification_type)} alt={notification.notification_type} />
          {getNotificationText(notification.notification_type)}
        </Type>
        <Wrap>
          <Title>{notification.title}</Title>
          <br />
          <br />
          <Sub>{notification.message}</Sub>
          <br />
          <br />
          <br />
          <small>{formatDate(notification.created_at)}</small>
        </Wrap>
        <Button onClick={onClose}>OK</Button>
      </ModalContainer>
    </Overlay>,
    portalRoot
  );
};

export default NotificationDetailModal;

const Overlay = styled.div`
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999 !important;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 30px;
  padding: 30px;
  /* max-width: 400px; */
  width: 650px;
  height: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  float: right;
`;

const Type = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #875cff;
  font-size: 22px;
  font-weight: 700;
  padding-top: 30px;
  padding-left: 18px;
`;

const Title = styled.p`
  color: #404040;
  font-size: 24px;
  font-weight: 700;
`;

const Sub = styled.p`
  color: #404040;
  font-size: 16px;
  font-weight: 500;
`;

const Wrap = styled.div`
  padding-top: 30px;
  padding-left: 60px;
`;

const Button = styled.button`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  background-color: #875cff;
  padding: 10px 18px;
  border-radius: 20px;
  margin-right: 40px;
  float: right;

  cursor: pointer;
`;
