import React, { ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  // URL이 /registration이고, state가 없으면 접근 차단
  if (location.pathname === '/registration' && !location.state?.fromNavigation) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
