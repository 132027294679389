import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import Modal from 'react-modal';
import {
  ParticipantListContainer,
  LeftSection,
  RightSection,
  ParticipantItem,
  RatingSection,
  RatingContainer,
  ClaimXPButton,
  BtnWrap,
  Xp,
  LoadingOverlay,
  LoadingSpinner,
  ModalContent,
  CloseButton,
} from './DiscoverParticipantList.style';
import { images } from 'src/assets/discover/images';
import ClaimModal from './ClaimModal';
import checkedStar from 'src/assets/discover/checkedStar.svg';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import { XpClaim_ABI } from 'src/configs/contract-abi/XpClaim';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'src/assets/modal/close.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

Modal.setAppElement('#root');

// 환경 변수에서 어드민 계정 정보를 가져옵니다.
const adminAddress = process.env.REACT_APP_ADMIN_WALLET_ADDRESS || '';
const adminPrivateKey = process.env.REACT_APP_ADMIN_WALLET_PRIVATE_KEY || '';

interface ParticipantListProps {
  participants: { id: number; user: string }[];
  onXpClaimed: () => void; // XP 클레임 후 호출할 콜백 함수
}

const DiscoverParticipantList: React.FC<ParticipantListProps> = ({ participants, onXpClaimed }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [points, setPoints] = useState<number>(4); // 점수(평점)
  const [rating, setRating] = useState<number>(0);
  const [isRatingFixed, setIsRatingFixed] = useState<boolean>(false); // 클릭 후 고정되는 상태
  const [hoverRating, setHoverRating] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isClaimed, setIsClaimed] = useState<boolean>(false); // XP가 이미 클레임 되었는지 여부
  const contractAddress = '0x15e7a34b6a5aBf8b0aD4FcD85D873FD7e7163E97';
  const contractABI = XpClaim_ABI;
  const location = useLocation();
  const projectData = location.state;
  const projectName = projectData?.pjt_name || 'Unknown Project';
  const projectId = projectData?.pjt_id;

  const userId = useSelector((state: RootState) => state.user.user_id);

  useEffect(() => {
    const preloadImage = (url: string) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = url;
      document.head.appendChild(link);
    };

    // 필요한 이미지들 미리 로드
    preloadImage(images.profile);
    preloadImage(images.checked_star);
    preloadImage(images.star2);

    const checkIfClaimed = async () => {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const walletAddress = accounts[0];

        const response = await axios.post(`${API_BASE_URL}/api/user/profile/check-already-claimed`, {
          walletAddress,
          project_id: projectData?.pjt_id,
          participation: 'Discover',
        });

        if (response.data.alreadyClaimed) {
          setIsClaimed(true);
        }
      } catch (error) {
        console.error('Error checking claim status:', error);
      }
    };

    checkIfClaimed();
  }, [projectData]);

  const truncateProjectName = (name: string, maxLength: number) => {
    return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
  };
  // const truncatedProjectName = truncateProjectName(projectName, 6);

  // 메타마스크 서명창이 안뜨는 경우
  const handleClaimXPRelay = async () => {
    if (isLoading || isClaimed) return; // 중복 처리 및 이미 클레임한 경우 방지
    setIsError(false);
    setPoints(rating);

    setIsModalOpen(true);
    setIsLoading(true);

    try {
      const web3 = new Web3((window as any).ethereum);
      const accounts = await web3.eth.getAccounts();
      const walletAddress = accounts[0];
      const xpPoints = 10;

      const contract = new web3.eth.Contract(contractABI, contractAddress);

      const data = contract.methods.claimXP(walletAddress, xpPoints).encodeABI();
      // 사람이 읽을 수 있는 서명 메시지 작성
      const message = `XP 클레임 요청: ${xpPoints} 포인트를 ${walletAddress} 지갑에 추가합니다.`;

      // MetaMask에 서명 요청 보내기
      const signedTransaction = await (window as any).ethereum.request({
        method: 'personal_sign',
        params: [web3.utils.utf8ToHex(message), walletAddress],
      });

      // Relayer 서버에 서명된 트랜잭션 데이터 전송
      const response = await axios.post(`${API_BASE_URL}/api/metadata/relay`, {
        signedTransaction,
        data,
        walletAddress,
        xpPoints,
        projectId,
        rating,
        contractABI,
        contractAddress,
      });

      if (response.data.status !== 'success') {
        throw new Error('Transaction failed');
      }

      setIsSuccess(true);

      const { transactionHash } = response.data;
      console.log(xpPoints);

      await axios.post(`${API_BASE_URL}/api/user/profile/update-xp-balance`, {
        walletAddress,
        xpPoints: xpPoints,
        pjt_id: projectId,
        rating,
      });

      await axios.post(`${API_BASE_URL}/api/user/profile/update-history`, {
        walletAddress,
        date: new Date().toISOString(),
        participation: 'Discover',
        activity: `Rated on Project ${projectName}`,
        xpEarned: xpPoints,
        transactionId: transactionHash,
        project_id: projectId,
        rating,
      });
      setIsClaimed(true); // 클레임 후 버튼 비활성화
      onXpClaimed(); // XP 클레임 후 데이터를 업데이트하는 콜백 호출

      // Claim 성공 시, 공지사항 생성 API 호출
      const notificationPayload = {
        title: `Your rating transaction for ${projectName} has been completed successfully`,
        content: `Your discover has been rated for the project. Check it out now!`,
        notification_type: 'active', // 공지사항 타입을 mission으로 설정
        user_id: userId,
      };

      const notificationResponse = await axios.post(
        `${API_BASE_URL}/api/admin/system-notifications`,
        notificationPayload
      );

      if (notificationResponse.status === 201) {
        console.log('Notification created successfully', notificationResponse.data);
      }
    } catch (error) {
      console.error('XP 클레임 중 오류 발생:', error);
      alert('XP Claimed Error. Please try it again.');
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleStarClick = (index: number) => {
    setRating(index + 1);
    setIsRatingFixed(true);
  };

  const handleStarHover = (index: number) => {
    if (!isRatingFixed) {
      setHoverRating(index + 1);
    }
  };

  const handleStarLeave = () => {
    if (!isRatingFixed) {
      setHoverRating(0);
    }
  };

  const displayRating = isRatingFixed ? rating : hoverRating || rating;

  return (
    <>
      <ParticipantListContainer>
        <LeftSection>
          <p>Participant List</p>
          <div>
            {participants.map((participant) => (
              <ParticipantItem key={participant.id}>
                <img
                  src={participant.user ? `${API_BASE_URL}/${participant.user}` : images.profile}
                  alt={`Participant ${participant.id}`}
                />
              </ParticipantItem>
            ))}
          </div>
        </LeftSection>
        <RightSection>
          <RatingSection>
            <p>Please rate using one of the 5 scores</p>
            <RatingContainer>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <img
                    src={index < displayRating ? images.checked_star : images.star2}
                    alt="Rating Star"
                    key={index}
                    onClick={() => handleStarClick(index)}
                    onMouseEnter={() => handleStarHover(index)}
                    onMouseLeave={handleStarLeave}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
              <div
                style={{
                  marginLeft: '20px',
                  marginTop: '15px',
                  fontWeight: 'bold',
                  fontSize: '30px',
                  color: '#210d5c',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {displayRating}
              </div>
            </RatingContainer>
            <BtnWrap>
              <Xp>
                Each rating earns you <span>10 XP!</span>
              </Xp>

              <ClaimXPButton onClick={handleClaimXPRelay} disabled={isClaimed}>
                {isClaimed ? 'Claimed' : 'Claim XP'}
              </ClaimXPButton>
            </BtnWrap>
          </RatingSection>
        </RightSection>
        <ClaimModal
          isOpen={isModalOpen}
          onClose={closeModal}
          points={points}
          loading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          onRetry={handleClaimXPRelay}
        />
        {/* <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Wallet Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <ModalContent>
            <CloseButton onClick={closeModal}>
              <CloseIcon />
            </CloseButton>
          </ModalContent>
        </Modal> */}
      </ParticipantListContainer>
    </>
  );
};

export default DiscoverParticipantList;
