// src/components/common/MessageModal.tsx
import React from 'react';
import {
  ModalOverlay,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  SendButton,
  CancelButton,
  Title,
  TextArea,
} from './MessageModal.style';

interface MessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
  email?: string[];
  setEmail?: (value: string[]) => void;
  setTitle?: (value: string) => void;
  setContent?: (value: string) => void;
  formLink?: string;
  onSend: () => void;
}

const NotificationModal: React.FC<MessageModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  setTitle,
  setContent,
  formLink = '(Optional) Also send to a discord channel:',
  onSend,
}) => {
  // 이메일 수정 핸들러

  if (!isOpen) return null;
  console.log(content);
  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            <h2>Send Messages</h2>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <Title type="text" value={title} onChange={(e) => setTitle?.(e.target.value)} placeholder="Title" />
            </div>
            <div>
              <TextArea value={content} onChange={(e) => setContent?.(e.target.value)} placeholder="Content" />
            </div>
          </ModalBody>
          <ModalFooter>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SendButton onClick={onSend}>Send</SendButton>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default NotificationModal;
