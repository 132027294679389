// src/components/dashboard/NotificationList.style.tsx
import styled from 'styled-components';

export const NotificationContainer = styled.div`
  position: absolute;
  bottom: 15vh; /* Sidebar 아이콘 아래로 위치 */
  left: 60px;
  width: 485px;
  max-height: 300px;
  overflow-y: scroll;
  border: 5px solid #e8e8e8;
  background-color: white;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  border-radius: 20px;
  z-index: 1000;
  padding: 10px;
`;

export const NotificationItem = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: left;
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
  gap: 10px;

  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }

  &:last-child {
    border-bottom: none;
  }

  div {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%; /* 내부 요소들을 양쪽 끝으로 배치 */
  }
`;

export const NotificationTitle = styled.span`
  font-size: 15px;
  font-weight: 500;
  color: #404040;
  font-weight: bold;
`;

export const NotificationTime = styled.span`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  margin-left: 35px;
`;

export const Img = styled.img`
  width: 25px;
  height: 25px;
`;
