// src/pages/dashboard/Sidebar.tsx
import React, { useState, useEffect } from 'react';
import { PATH } from '../../../constants/path';
import {
  SidebarContainer,
  Logo,
  NavItem,
  Icon,
  Name,
  NotificationItem,
  Badge,
} from '../../dashboard/sidebar/Sidebar.style';
import { images } from '../../../assets/dashboard/images';
import { useNavigate } from 'react-router-dom';
import Modal from 'src/components/admin/modal/common/Modal';
import ContributionModal from 'src/components/admin/modal/redirectMotal/Contribution';
import NotificationList from 'src/components/admin/modal/notification/NotificationList';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import NotificationDetailModal from 'src/components/admin/modal/notification/detail/NotificationDetailModal';

interface SidebarProps {
  onGovernanceClick: () => void; // 모달 열기 함수
}

interface AppNotification {
  id: number;
  title: string;
  message: string;
  created_at: string;
  notification_id: number;
  notification_type: string;
  is_read: boolean;
  reads?: Read[];
}

interface Read {
  read_id: number;
  user_id: number;
  notification_id: number;
  is_read: boolean;
  read_at: string;
}

const Sidebar: React.FC<SidebarProps> = ({ onGovernanceClick }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 관리
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // 알림창 상태 관리
  const [selectedNotification, setSelectedNotification] = useState<AppNotification | null>(null); // 선택된 알림
  const [unreadCount, setUnreadCount] = useState<number>(0); // 읽지 않은 알림 개수 상태
  const [unreadRefreshTrigger, setUnreadRefreshTrigger] = useState(false);
  const user = useSelector((state: RootState) => state.user); // 로그인된 사용자 정보

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/unread/count/${user.user_id}`);
        setUnreadCount(response.data.data.length); // 읽지 않은 알림 개수 설정
      } catch (error) {
        console.error('Error fetching unread notifications count:', error);
      }
    };

    fetchUnreadCount();
    const interval = setInterval(fetchUnreadCount, 60000); // 60000ms = 1분

    // 컴포넌트 언마운트 시 인터벌 정리
    return () => clearInterval(interval);
  }, [user.user_id, unreadRefreshTrigger]); // 사용자 ID 변경 시 다시 호출

  const handleContributionClick = () => {
    setIsModalOpen(true); // 모달 열기
  };

  const handleLogoClick = () => {
    navigate('/main/dashboard'); // 메인 페이지 경로로 이동
  };
  // Governance 클릭 시 모달 열기 및 네비게이션 막기
  // const handleGovernanceClick = (event: React.MouseEvent) => {
  //   event.preventDefault(); // 네비게이션 막기
  //   setIsModalOpen(true); // 모달 열기
  // };

  // // 모달 닫기 함수
  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };
  const toggleNotification = () => {
    console.log('open');
    setIsNotificationOpen(!isNotificationOpen); // 알림창 열고 닫기 토글
  };

  const handleNotificationClick = (notification: AppNotification) => {
    setSelectedNotification(notification); // 상세 알림 설정
    setIsNotificationOpen(false); // 알림 목록 닫기
  };

  const handleCloseDetailModal = () => {
    setSelectedNotification(null); // 상세 알림 초기화
  };

  const handleMarkAsRead = () => {
    setUnreadRefreshTrigger((prev) => !prev); // 트리거를 변경하여 useEffect 실행
  };

  return (
    <SidebarContainer>
      <Logo style={{ backgroundImage: `url(${images.sidebarLogo})` }} onClick={handleLogoClick} />
      <NavItem to={PATH.DASHBOARD}>
        <Icon $imageUrl={images.dashboardIcon} />
        <Name>Dashboard</Name>
      </NavItem>
      <NavItem to={PATH.DEAL} onClick={handleContributionClick}>
        <Icon $imageUrl={images.dealIcon} style={{ width: '28px', height: '28px' }} />
        <Name>Deal</Name>
      </NavItem>
      <NavItem to={PATH.DISCOVER}>
        <Icon $imageUrl={images.discoverIcon} />
        <Name>Discover</Name>
      </NavItem>
      <NavItem to={PATH.CONTRIBUTION}>
        <Icon $imageUrl={images.contributionIcon} />
        <Name>Contribution</Name>
      </NavItem>
      <NavItem to={PATH.GOVERNANCE} onClick={onGovernanceClick}>
        <Icon $imageUrl={images.governanceIcon} />
        <Name>Governance</Name>
      </NavItem>
      <NotificationItem onClick={toggleNotification}>
        <Icon $imageUrl={images.notificationsIcon} />
        {unreadCount > 0 && <Badge>{unreadCount}</Badge>} {/* 읽지 않은 알림 개수 표시 */}
        {isNotificationOpen && (
          <NotificationList
            onNotificationClick={handleNotificationClick} // 클릭 시 호출
            onMarkAsRead={handleMarkAsRead} // 읽음 처리 후 트리거 실행
            onClose={() => setIsNotificationOpen(false)} // 닫기
          />
        )}
      </NotificationItem>
      {selectedNotification && (
        <NotificationDetailModal notification={selectedNotification} onClose={handleCloseDetailModal} />
      )}
      {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2>Access Restricted</h2>
        <p>You cannot access the Governance page at this time.</p>
        <button onClick={handleCloseModal}>Close</button>
      </Modal> */}
    </SidebarContainer>
  );
};

export default Sidebar;
