// src/components/common/MessageModal.tsx
import React from 'react';
import {
  ModalOverlay,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  SendButton,
  CancelButton,
  Title,
  TextArea,
  Option,
  Button,
} from './MessageModal.style';

interface MessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: () => void;
  title: string;
  content: string;
  channel: string;
  email: string[];
  setEmail: (value: string[]) => void;
  setTitle: (value: string) => void;
  setContent: (value: string) => void;
  setChannel: (value: string) => void;

  formLink?: string;
}

const MessageModal: React.FC<MessageModalProps> = ({
  isOpen,
  onClose,
  onSend,
  title,
  content,
  channel,
  setTitle,
  setContent,
  setChannel,
  email,
  setEmail,
  formLink = '(Optional) Also send to a discord channel:',
}) => {
  // 이메일 수정 핸들러
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emails = e.target.value.split(',').map((email) => email.trim()); // 콤마로 구분해서 배열로 변환
    setEmail(emails); // 이메일 배열 업데이트
  };

  // 템플릿 변경 핸들러
  const handleTemplateChange = (templateType: 'type1' | 'type2' | 'type3') => {
    if (templateType === 'type1') {
      setTitle(`{deal_name} Payment Instructions`);
      setContent(`Hello {user_name},

Thank you for your interest in the <strong>{deal_name}</strong> project. 

Below are the payment instructions for your investment. Please ensure that the funds are transferred by the specified due date.

<strong>Payment Details:</strong>  
- <strong>Final Allocation:</strong> <u>{user_final_allocation} USDT</u>  
- <strong>Payment Deadline:</strong> <u>{paymentDueDate}, by midnight</u>  
- <strong>Wallet Address:</strong> <u>0x2dB0544f170157077B60baB07f33b1E3d32750D6</u>  

Once the payment is completed, please submit the transaction hash via the following form:
{modalChannel}

<strong>Important Notice:</strong>  
Komm DAO does not assume liability for any loss or misplacement of funds due to network selection errors or incorrect address entry. For additional inquiries or if you're unable to meet the payment deadline, please respond to this email or open a ticket on our Discord channel. Failure to complete payment by the deadline may result in penalties.

Thank you.`);
    } else if (templateType === 'type2') {
      setTitle(`Final Notice: Payment Deadline for {deal_name}
`);
      setContent(`Hello <strong>{user_name}</strong>,

This is a final reminder regarding your pending payment for the <strong>{deal_name}</strong> project. Failure to complete the payment by the specified deadline may result in <strong>exclusion from priority access to future deals</strong> and <strong>potential removal from the member list</strong>.

<strong>Final Deadline:</strong>
- <strong>Date:</strong> <u>December 23, 2024</u>
- <strong>Time:</strong> <u>6:00 PM (KST)</u>

<strong>Payment Details:</strong>
- <strong>Final Allocation:</strong> <u>{user_final_allocation} USDT</u>
- <strong>Wallet Address:</strong> <u>0x2dB0544f170157077B60baB07f33b1E3d32750D6</u>

Once the payment is completed, please submit the transaction hash via the following form:
<u>{modalChannel}</u>

<strong>Important Notice:</strong>
Komm DAO does not assume liability for any loss or misplacement of funds due to network selection errors or incorrect address entry. For additional inquiries or if you're unable to meet the payment deadline, please respond to this email or open a ticket on our Discord channel.

Best regards,`);
    } else if (templateType === 'type3') {
      setTitle(`Payment Confirmed for {deal_name}
`);
      setContent(`Hello {user_name},

Your payment for the <strong>{deal_name}</strong> project has been successfully confirmed.

The details are now available in the Portfolio section of your Dashboard. We will keep you informed with the latest updates on the project.

Thank you.`);
    }
  };

  if (!isOpen) return null;
  console.log(content);
  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            <h2>Send Messages</h2>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <Title type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
            </div>
            <div>
              <TextArea value={content} onChange={(e) => setContent(e.target.value)} placeholder="Content" />
            </div>
            <div>
              <label>{formLink}</label>
              <Option
                type="text"
                value={email.join(', ')} // 이메일 배열을 콤마로 구분해서 표시
                onChange={handleEmailChange} // 이메일 값이 변경되면 handleEmailChange 호출
                placeholder="Enter emails, separated by commas"
              />
            </div>
            <div className="switch">
              {/* 템플릿 선택 버튼 추가 */}
              <Button onClick={() => handleTemplateChange('type3')}>Confirm</Button>
              <Button onClick={() => handleTemplateChange('type2')}>Deadline</Button>
              <Button onClick={() => handleTemplateChange('type1')}>Payment</Button>
            </div>
          </ModalBody>
          <ModalFooter>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SendButton onClick={onSend}>Send</SendButton>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default MessageModal;
