// src/components/dashboard/NotificationList.tsx
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  NotificationContainer,
  NotificationItem,
  NotificationTitle,
  NotificationTime,
  Img,
} from './NotificationList.style';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import linkIn from 'src/assets/main/logout.svg';
import campaign from 'src/assets/main/campaign.svg';
import mission from 'src/assets/main/notifications_active.svg';
import active from 'src/assets/main/task_alt.svg';
import { useNavigate } from 'react-router-dom';

interface NotificationListProps {
  onClose?: () => void; // 팝업 외부 클릭 시 닫는 함수
  onNotificationClick: (notification: AppNotification) => void;
  onMarkAsRead: () => void;
}
interface Read {
  read_id: number;
  user_id: number;
  notification_id: number;
  is_read: boolean;
  read_at: string;
}

interface AppNotification {
  id: number;
  title: string;
  message: string;
  created_at: string;
  notification_id: number;
  notification_type: string;
  is_read: boolean;
  reads?: Read[];
}

const NotificationList: React.FC<NotificationListProps> = ({ onClose, onNotificationClick, onMarkAsRead }) => {
  const user = useSelector((state: RootState) => state.user);
  const [notifications, setNotifications] = useState<AppNotification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedNotification, setSelectedNotification] = useState<AppNotification | null>(null); // 선택된 알림
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 열림 상태 관리
  const navigate = useNavigate();

  console.log(notifications);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/unread/${user.user_id}`);
        setNotifications(response.data.data); // 가져온 데이터를 상태에 저장
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false); // 로딩 상태를 종료
      }
    };

    fetchNotifications();
  }, []);

  const handleNotificationClick = async (notification: AppNotification) => {
    if (notification.title.startsWith('New Deal')) {
      navigate('/main/deal'); // New Deal 관련 경로로 이동
    } else if (notification.title.startsWith('New Discover')) {
      navigate('/main/discover'); // New Discover 관련 경로로 이동
    } else if (notification.title.startsWith('New Contribution')) {
      navigate('/main/contribution'); // New Contribution 관련 경로로 이동
    } else if (notification.notification_type === 'announcement') {
      onNotificationClick(notification); // 부모로 notification 전달
      setIsModalOpen(true); // 모달 열기
    } else {
      console.warn(`Unhandled notification title: ${notification.title}`);
    }
    try {
      const response = await axios.post(`${API_BASE_URL}/api/admin/mark-as-read/${user.user_id}`, {
        notification_id: notification.notification_id,
      });

      if (response.data.success) {
        console.log('Notification marked as read');
        // 읽음 처리 후 알림의 `is_read` 상태를 업데이트
        setNotifications((prev) =>
          prev.map((notif) =>
            notif.notification_id === notification.notification_id ? { ...notif, is_read: true } : notif
          )
        );

        if (onMarkAsRead) {
          onMarkAsRead(); // 트리거 업데이트
        }
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  useEffect(() => {
    console.log('Selected Notification updated:', selectedNotification);
  }, [selectedNotification]);

  const handleCloseDetailModal = () => {
    setSelectedNotification(null); // 선택된 알림 초기화
    setIsModalOpen(false); // 모달 닫기
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit', // 수정된 부분
      minute: '2-digit', // 수정된 부분
      hour12: true, // A.M/P.M 형식 활성화
    };

    if (isToday) return `Today ${date.toLocaleTimeString('en-US', timeOptions)}`;
    if (isYesterday) return `Yesterday ${date.toLocaleTimeString('en-US', timeOptions)}`;

    // Default to full date if not today or yesterday
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'announcement':
        return campaign;
      case 'active':
        return active;
      case 'mission':
        return mission;
      default:
        return campaign; // 기본값
    }
  };

  const portalRoot = document.getElementById('portal-root'); // Portal이 렌더링될 DOM
  console.log('Portal root:', portalRoot);
  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <>
      <NotificationContainer>
        {/* 배경 클릭 시 닫기 */}
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }} />

        {/* 알림 로딩 상태 표시 */}
        {loading ? (
          <p>Loading notifications...</p>
        ) : notifications.length === 0 ? (
          <p>No new notifications</p>
        ) : (
          notifications.map((notification) => (
            <NotificationItem key={notification.id} onClick={() => handleNotificationClick(notification)}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Img src={getNotificationIcon(notification.notification_type)} alt={notification.notification_type} />
                  <NotificationTitle>{notification.title}</NotificationTitle>
                </div>
                <img src={linkIn} alt="logout" style={{ marginLeft: 'auto' }} />
              </div>
              <NotificationTime>{formatDate(notification.created_at)}</NotificationTime>
            </NotificationItem>
          ))
        )}
      </NotificationContainer>
      {/* {isModalOpen && <NotificationDetailModal onClose={handleCloseDetailModal} />} */}
    </>,
    portalRoot // Portal을 통해 렌더링
  );
};

export default NotificationList;
